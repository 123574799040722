import { useContext } from "react";
import CommentBox from "../CommentBox";
import CommentList from "../CommentList";
import { CommentContext } from "./ForumUtils";

export default function ForumComments() {
  const {
    isLoading,
    comments,
    canLoadMore,
    deleteComment,
    editComment,
    loadMore,
    publishComment,
    updateVote,
    updateReward,
    thread,
  } = useContext(CommentContext);
  return (
    <div className="flex flex-col md:px-5 sm:px-5 xs:px-5 bg-white w-full">
      <div className="flex"></div>
      <CommentBox publishComment={publishComment} showVisibility={false} />
      <CommentList
        isLoading={isLoading}
        comments={comments}
        deleteComment={deleteComment}
        editComment={editComment}
        canLoadMore={canLoadMore}
        loadMore={loadMore}
        updateVote={updateVote}
        updateReward={updateReward}
        thread={thread}
      />
    </div>
  );
}
