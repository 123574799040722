import { createContext } from "react";
import { Comment, CreateCommentParams } from "../../api";
import { CollaborationThread } from "../../api/types";

export type ForumFormValues = {
  title: string;
  content: string;
};

export const threadToFormValues = (
  thread: CollaborationThread
): ForumFormValues => {
  return {
    title: thread.title,
    content: thread.content_html,
  };
};

export interface CommentContextProps {
  isLoading: boolean;
  comments: Comment[];
  canLoadMore: boolean;
  loadMore: () => void;
  deleteComment: (commentId: number) => void;
  editComment: (comment: Comment) => void;
  publishComment: (data: CreateCommentParams) => Promise<void>;
  updateVote: (commentId: number) => void;
  updateReward: (commentId: number) => void;
  thread: CollaborationThread | null;
}

export const CommentContext = createContext<CommentContextProps>({
  isLoading: false,
  comments: [],
  canLoadMore: false,
  loadMore: () => {},
  deleteComment: (commentId: number) => {},
  editComment: (comment: Comment) => {},
  publishComment: (data: CreateCommentParams) => Promise.resolve(),
  updateVote: (commentId: number) => {},
  updateReward: (commentId: number) => {},
  thread: null,
});
