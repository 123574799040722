import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import * as api from "../../api";
import {
  Comment,
  CreateCommentParams,
  getCommentCollabThread,
  postCommentCollabThread,
  rewardCollabThread,
  updateVoteCollabThread,
} from "../../api";
import { CollaborationThread } from "../../api/types";
import { classNames } from "../utils";
import ErrorWrapper from "../Utils/ErrorWrapper";
import ForumComments from "./ForumComments";
import { CommentContext } from "./ForumUtils";

type ThreadActionButtonsProps = {
  thread: CollaborationThread;
  expandCommend?: boolean;
  onError: (error: any) => void;
};

const ThreadActionButtons = ({
  thread,
  expandCommend = false,
  onError,
}: ThreadActionButtonsProps) => {
  const [showComment, setShowComment] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);

  useEffect(() => {
    setShowComment(expandCommend);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandCommend]);

  useEffect(() => {
    if (showComment) {
      const params: Record<string, any> = {
        page,
        limit: 15,
      };
      getCommentCollabThread(thread.id, new URLSearchParams(params)).then(
        (response) => {
          if (response.data) {
            setComments([...comments, ...response.data.items]);
            setPages(response.data.pages);
            setTotalComments(response.data.total);
            setCanLoadMore(page < response.data.pages);
          }
        }
      );
    } else {
      setComments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComment, page]);

  const loadMore = () => {
    if (page < pages) {
      setPage((page) => page + 1);
    }
  };

  const deleteComment = (commentId: number) => {
    api
      .deleteComment(commentId)
      .then((response) => {
        if (response.data) {
          setComments([
            ...[],
            ...comments.filter((comment) => comment.id !== commentId),
          ]);
        }
      })
      .catch((error) => onError(error));
  };

  const editComment = (comment: Comment) => {
    api
      .editComment(comment)
      .then((response) => {
        comments.forEach((item, index) => {
          if (comment.id === item.id) {
            comments[index] = { votes: item.votes, ...response.data.data };
          }
        });
        setComments([...[], ...comments]);
      })
      .catch((error) => onError(error));
  };

  const publishComment = async (data: CreateCommentParams) => {
    postCommentCollabThread(thread.id, data)
      .then((response) => {
        if (response.data) {
          setComments([...[response.data.data], ...comments]);
          setTotalComments((total) => total + 1);
        }
      })
      .catch((error) => onError(error));
  };

  const updateVote = (commentId: number) => {
    updateVoteCollabThread(commentId)
      .then((response) => {
        if (response.data) {
          comments.forEach((comment, index) => {
            if (comment.id === commentId) {
              comments[index] = response.data.data;
            }
          });
          setComments([...[], ...comments]);
        }
      })
      .catch((error) => onError(error));
  };

  const updateReward = (commentId: number) => {
    rewardCollabThread(commentId)
      .then((response) => {
        if (response.data) {
          comments.forEach((comment, index) => {
            if (comment.id === commentId) {
              comments[index] = response.data.data;
            }
          });
          setComments([...[], ...comments]);
        }
      })
      .catch((error) => onError(error));
  };

  const commentValues = {
    isLoading,
    comments,
    canLoadMore,
    deleteComment,
    editComment,
    loadMore,
    publishComment,
    updateVote,
    updateReward,
    thread,
  };
  return (
    <CommentContext.Provider value={commentValues}>
      <div className="flex flex-row mt-3 text-xs">
        <button
          className={classNames(
            "flex w-10 h-6 rounded-xl bg-gray-200 items-center justify-center",
            showComment ? "bg-blue-500 text-white" : ""
          )}
          onClick={() => setShowComment(!showComment)}
        >
          <ChatBubbleLeftIcon className="w-3 h-3 mr-1" />
          {comments.length === 0 ? thread.comment_count : totalComments}
        </button>
      </div>
      {showComment && <ForumComments />}
    </CommentContext.Provider>
  );
};

export default ErrorWrapper(ThreadActionButtons);
